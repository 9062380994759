var FancyTiles = function (wrapperID, options) {
    this.id = wrapperID;
    this.position = 0;
    this.strip = 0;
    this.itemWidth = 0;
    this.nextPosition = 0;
    this.options = options;
    this.stripWidth = 0;
    this.prevController = null;
    this.nextController = null;
    this.divide = 4;
    this.widthModifier = 1;
    var self = this;

    this.set = function (prop, val) {
        this[prop] = val;
    };

    this.initAttributes = function(){
        self.set('position',0);
        self.set('strip',0);
        self.set('itemWidth',0);
        self.set('nextPosition',0);
        self.set('stripWidth',0);
        self.set('prevController',null);
        self.set('nextController',null);
        self.set('divide',4);
    };


    this.init = function () {
        this.initAttributes();
        this.checkOptions();
        this.setDivide();
        this.screen();
        this.touch();
    };

    this.initForOtherModules = function(){
        this.initAttributes();
        this.checkOptions();
        this.setDivide();
        this.screen();
    };

    this.screen = function () {
        window.addEventListener('resize', function(){
            self.initAttributes();
            self.checkOptions();
            self.setDivide();
        },false);
    };

    this.setDivide = function () {
        this.landscapeTileGroups();
        this.calculateDivide();
        var self = this;

        setTimeout(function() {
            self.construct();
            self.setControllers();
        },100);
    };

    this.landscapeTileGroups = function () {
        if (this.options.isDoubleRow === true){
            if (window.innerWidth < 992) {
                self.set('widthModifier', 2);
            } else {
                self.set('widthModifier', 1);
            }
        }else{
            self.set('widthModifier', 1);
        }
    };

    this.checkOptions = function () {
        if (options === undefined || options.divide === undefined) {
            self.set('options', {
                divide: {
                    xs: 1,
                    sm: 2,
                    md: 3,
                    lg: 4,
                    xl: 4,
                    def: 4
                }
            })
        }

    };

    this.calculateDivide = function () {
        if (window.innerWidth < 576) {
            self.set('divide', this.options.divide.xs);
        } else if (window.innerWidth >= 576 && window.innerWidth < 768) {
            self.set('divide', this.options.divide.sm);
        } else if (window.innerWidth >= 768 && window.innerWidth < 992) {
            self.set('divide', this.options.divide.md);
        } else if (window.innerWidth >= 992 && window.innerWidth < 1200) {
            self.set('divide', this.options.divide.lg);
        } else if (window.innerWidth >= 1200) {
            self.set('divide', this.options.divide.xl);
        }
    };

    this.touch = function () {
        var xDown = null;
        var yDown = null;

        var tileStrip = document.getElementById(this.id).getElementsByClassName('tiles-strip')[0];

        tileStrip.addEventListener('touchmove', touchmove, false);
        function touchmove(e){
            if (!xDown || !yDown) {
                return;
            }

            var xUp = e.touches[0].clientX;
            var yUp = e.touches[0].clientY;

            var xDiff = xDown - xUp;
            var yDiff = yDown - yUp;

            if (Math.abs(xDiff) > Math.abs(yDiff)) {/*most significant*/
                if (xDiff > 0) {
                    /* left swipe */
                    self.next();
                } else {
                    /* right swipe */
                    self.prev();
                }
            } else {
                e.preventDefault();
                if (yDiff > 0) {
                    /* up swipe */
                } else {
                    /* down swipe */
                }
            }

            /* reset values */
            xDown = null;
            yDown = null;
        }

        tileStrip.addEventListener('touchstart', touchstart, false);
        function touchstart(e){
            xDown = e.touches[0].clientX;
            yDown = e.touches[0].clientY;
        }
    };

    this.construct = function () {
        var ID = this.id;
        var wrapper = document.getElementById(ID);

        this.set('strip', document.querySelectorAll('#' + this.id + ' .tiles-strip')[0]);
        this.set('itemWidth', Math.ceil(parseInt(getComputedStyle(wrapper).width) / this.divide));

        this.strip.style.transform = 'translateX(0px)';

        var groups = document.querySelectorAll('#' + this.id + ' .tiles-strip .tile-group');

        for (var i = 0; i < groups.length; i++) {
            var group = groups[i];
            if (!this.options.isDoubleRow) {
                group.style.width = this.itemWidth * this.widthModifier + 'px';
            }
            var tiles = group.getElementsByClassName('tile');
            for (var tileIndex = 0; tileIndex < tiles.length; tileIndex++) {
                var tile = tiles[tileIndex];

                tile.style.width = this.itemWidth + 'px';
                if (this.options.isSquare) {
                    tile.style.height = this.itemWidth + 'px';
                }
            }
        }

        if (this.widthModifier == 1) {
            this.set('stripWidth', Math.abs(groups.length * this.itemWidth * this.widthModifier));
        }else{
            var items = document.querySelectorAll('#' + this.id + ' .tiles-strip .tile-group a');
            if(items.length%2 == 0){
                this.set('stripWidth', Math.abs(groups.length * this.itemWidth * this.widthModifier));
            }else{
                this.set('stripWidth', Math.abs((groups.length) * this.itemWidth * this.widthModifier) - this.itemWidth);
            }
        }
    };

    this.setControllers = function () {
        var controllers = document.querySelectorAll('#' + this.id + ' .tiles-control');

        if (controllers.length) {
            for (var i = 0; i < controllers.length; i++) {
                var controller = controllers[i];

                if (controller.getAttribute('data-action') === 'prev') { //controllers[0] next gomb
                    this.set('prevController', controller);

                    controller.className = controller.className.replace(' disabled', '');
                    controller.className = controller.className + ' disabled';
                    controller.removeEventListener('click',this.controllerClick, false);
                    if ((this.stripWidth/this.itemWidth) > this.divide){
                        controller.addEventListener('click', this.controllerClick, false);
                    }
                }

                if (controller.getAttribute('data-action') === 'next') { //controllers[1] next button
                    this.set('nextController', controller);

                    controller.removeEventListener('click',this.controllerClick, false);
                    if ((this.stripWidth/this.itemWidth) > this.divide){
                        controller.className = controller.className.replace(' disabled', '');
                        controller.addEventListener('click', this.controllerClick, false);
                    }else{
                        controller.className = controller.className.replace(' disabled', '');
                        controller.className = controller.className + ' disabled';
                    }
                }

            }

        }

    };

    this.controllerClick = function (e) {
        var btn = e.target;
        var nodeName = e.target.nodeName;
        if (nodeName !== 'BUTTON')
            btn = btn.parentNode;

        var target = btn.getAttribute('data-target');
        var action = btn.getAttribute('data-action');

        self[action](btn);
    };

    this.move = function (direction, btn) {
        var drct = (direction === 'right') ? -1 : 1;
        this.set('nextPosition', (this.position + (this.itemWidth * drct)));

        this.prevController.className = this.prevController.className.replace(' disabled', '');
        this.nextController.className = this.nextController.className.replace(' disabled', '');

        if (btn !== undefined) {
            if (this.nextPosition === 0) {
                btn.className = btn.className + ' disabled';
            }

            if (this.nextPosition === (drct * (this.stripWidth - (this.divide * this.itemWidth)))) {
                btn.className = btn.className + ' disabled';
            }
        }
        // this.effectExcurse(10, this.nextPosition);
        this.strip.style.transform = 'translateX(' + this.nextPosition + 'px)';
        this.set('position', this.nextPosition);
    };

    this.prev = function (btn) {
        if (this.position === 0) {
            return false;
        }
        this.move('left', btn);
    };

    this.next = function (btn) {
        if (this.position === (-1 * (this.stripWidth - (this.divide * this.itemWidth)))) {
            return false;
        }
        this.move('right', btn);
    };

    this.effectExcurse = function (i, x) {
        var strip = this.strip;

        function effect(i, x) {
            setTimeout(function () {
                var mod = i % 2;
                var excursion = (mod === 0) ? -i * 2 : i * 2;
                strip.style.transform = 'translateX(' + (x - excursion) + 'px)';
                if (--i)
                    effect(i, x);
            }, Math.abs(i) * 10);
        }

        effect(i, x);
    };

};